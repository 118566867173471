import React from "react";
import ReactDOM from "react-dom/client"; // Updated import
import TopRadios from "./pages/TopRadios";

import "./index.scss";

// Create a root
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container); // New createRoot API

root.render(
  <React.StrictMode>
    <TopRadios />
  </React.StrictMode>
);
