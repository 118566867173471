import Styles from "./TopRadios.module.scss";
import useSWR from "swr";
import { getUrlParameter } from "../helpers/query.js";

const handleRadioClick = (shortcut) => {
  const link = `http://listen.play.cz/?shortcut=${shortcut}`; // DevSkim: ignore DS137138

  window.open(link, "playcz_player", "height=740, width=420");
};

const App = () => {
  const limit = parseInt(getUrlParameter("limit")) || 12;
  const mode = getUrlParameter("mode") || "top";

  const isRandom = mode === "random" ? true : false;
  const showCount = true;

  const fetcher = (url) => fetch(url).then((res) => res.json());
  const { data } = useSWR("https://api.play.cz/json/getTopRadios", fetcher);

  const topRadios =
    data &&
    Object.keys(data.data)
      .map((key) => {
        return { shortcut: key, ...data.data[key] };
      })
      .sort(() => (isRandom ? Math.random() - 0.5 : undefined))
      .splice(0, limit);

  //console.log({ limit, mode, data ,topRadios});

  return (
    <div className={Styles.page}>
      <div className={Styles.wrap}>
        {topRadios &&
          topRadios.map((radio, index) => {
            return (
              <div
                key={`${index}-${radio.shortcut}`}
                className={Styles.item}
                onClick={() => {
                  handleRadioClick(radio.shortcut);
                }}
              >
                <img
                  src={radio.logo.replace("http://", "https://")} // DevSkim: ignore DS137138
                  alt={radio.title}
                  title={radio.title}
                  className={Styles.logo}
                />
                {showCount && (
                  <span className={Styles.count}>{radio.listeners}</span>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default App;
